exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-galaxyways-js": () => import("./../../../src/pages/galaxyways.js" /* webpackChunkName: "component---src-pages-galaxyways-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-practice-js": () => import("./../../../src/pages/practice.js" /* webpackChunkName: "component---src-pages-practice-js" */),
  "component---src-pages-projects-contentful-project-title-js": () => import("./../../../src/pages/projects/{ContentfulProject.title}.js" /* webpackChunkName: "component---src-pages-projects-contentful-project-title-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-stylemate-js": () => import("./../../../src/pages/stylemate.js" /* webpackChunkName: "component---src-pages-stylemate-js" */)
}

