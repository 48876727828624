const data = [
  {
    id: 1,
    text: "Home",
    url: "/",
  },
  {
    id: 2,
    text: "Services",
    url: "/#Services",
  },
  {
    id: 3,
    text: "Projects",
    url: "/projects",
  },
  {
    id: 4,
    text: "About Me",
    url: "/#About",
  },
  {
    id: 5,
    text: "Contact",
    url: "/contact",
  },
]

export default data
